<template>
    
<div class="home-pizza">
    <NavbarHome class="my-3 ms-0"/>
    <div class="divider"></div>
    <div class="container-fluid">
        <h1 class="text-center title">Benvenuti in Virtual Pizzeria!</h1>
        <h2 class="text-muted text-center">La migliore pizzeria del mondo</h2>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-10">
                <transition-group 
                    appear
                    tag="div"
                    class="row"
                    v-for="category in categories"
                    :key="category.id"
                    @before-enter="beforeEnter"
                    @enter="enter"
                >
                    <h4>{{ category.name }} :</h4>
                    <hr>
                    <div 
                        v-for="(product, index) in category.products" 
                        :data-index="index" 
                        :key="product.id"
                        class="col-md-5 mx-4 mb-3">
                        <PizzaProduct
                            :key="product.id"
                            :product="product"/>
                    </div>
                    <hr>
                </transition-group>
            </div>
            
        </div>
        <br><br>
        <PizzaNav 
            class="mt-2"
            :categories="categories"
        />
    </div>
    </div>
</template>

<script>
    import gsap from 'gsap';
    import axios from 'axios'
    import PizzaNav from '@/components/PizzaNav.vue';
    import PizzaProduct from '@/components/PizzaProduct.vue';
    import NavbarHome from '@/components/NavbarHome.vue';

export default{
    name: 'PizzaHome',
    data(){
    return {
      categories: [],
      ingredients: [],
    }
  },
    components:{
    PizzaNav,
    PizzaProduct,
    NavbarHome

},
    
    mounted(){
    this.getCategories()
    const tl = gsap.timeline({ paused: true});
    tl.from(".title", { x:'+400', rotateY: 360, autoAlpha:0, duration:0.8});
    tl.from(".text-muted", { x:'+300', scale:0.2, autoAlpha:0, duration:1});
    tl.play()
    
    },
    beforeCreate(){
    this.$store.commit('inizializeStore')
    },
    methods:{
        async getCategories(){
            await axios.get('/api/v1/categories/')
                .then(response =>{
                    this.categories = response.data
                })
                .catch(error =>{
                    console.log(error)
                })
                axios.get('/api/v1/ingredients/')
                    .then(result=>{
                        this.ingredients = result.data
                    })
                    .catch(error =>{
                    console.log('error')
                })
                document.title = "Virtual Pizzeria"
        },
        beforeEnter(el){
            el.style.opacity = 0;
            el.style.transform = 'scale(0.5)';
            el.style.transform = 'translateY(200px)';

            },
        enter(el, done){
            gsap.to(el, {
            opacity: 1,
            scale:  1,
            y:0,
            duration: 1.2,
            onComplete: done,
            delay: el.dataset.index * 0.4
            })
        },
    }

}
</script>

<style scoped>
h1, h2, h3, h4, h5, h6, p, a, input{
  color: rgb(221, 224, 206);
  background-color: rgba(0,0,0,255);
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.home-pizza{
    color: rgb(221, 224, 206);
    background-color: rgba(0,0,0,255);
}
.title{
        color: rgb(16, 111, 59);
    }
    a {
  text-decoration: none;
}
  
</style>