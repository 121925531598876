<template>
    <NavbarHome class="my-3"/>
    <div class="FoodProduct container">
        <div class="divider"></div>
        <div class="card mb-3">
           
            <div class="card-header head1 back-green mb-2">
                <div class="row box-2">
                    <div class="col-6">
                        <h5 class="card-title back-green"><strong>{{ product.name}}</strong></h5>
                        <h5 class="card-title back-green">€ {{ product.price }}</h5>
                    </div>
                    <div class="col-6">
                        <h5 class="card-title back-green">{{ product.ingredients_list }}</h5>
                    </div>
                </div>
            </div>
            <!-- Card body -->
            <div class="row">
                <div v-show="product.imageURL != ' '" class="col-md-6 my-2">
                    <img v-bind:src="product.imageURL" class="card-img-top box" alt="...">
                </div>
                <div class="col-md-6">
                    <div 
                        class="card-body head2 back-green"
                        v-show="product_ingredients.length"
                        >
                        <div class="row">
                            <h4 class="card-text back-green">Togli ingredienti:</h4>
                            <div class="col-md-6 back-green mt-1"
                                 v-for="item in product_ingredients"
                                 >{{ item.additionName }}
                                 <button 
                                    class="btn btn-outline-success ms-5"
                                    @click="removeIngredient(item)"
                                    >Togli
                                    <i class="fa-solid fa-circle-minus text-danger"></i>
                                 </button>
                            </div>
                        </div>
                            <hr class="my-2">
                    </div>
                    <!-- sezione Ingredienti rimossi -->
                    <div v-show="withoutList.length" class="card-body back-green">
                        <div class="row">
                            <div class="col-12 back-green mt-1"
                                v-for="item in withoutList"
                                ><h5 class="back-green">
                                    <strong
                                        >Senza: 
                                    </strong>{{ item.additionName }}
                                    <button 
                                        class="btn btn-outline-success ms-5"
                                        @click="addIngredient(item)"
                                        >Ripristina
                                        <i class="fa-solid fa-circle-plus text-info"></i>
                                    </button>
                                </h5>
                                
                            </div>
                        </div>
                    </div>
                    <!-- sezione aggiunzioni -->
                    
                    <div v-show="withList.length" class="card-body back-green">
                        <div class="row">
                            <div class="col-12 back-green mt-1"
                                 v-for="item in withList"
                                 ><h5 class="back-green">
                                    <strong>Con aggiunta di: </strong>{{ item.addName }} € {{ item.addPrice }}
                                    <button 
                                    class="btn btn-outline-success ms-5"
                                    @click="removeAddition(item)"
                                    >Rimuovi
                                    <i class="fa-solid fa-circle-minus text-danger"></i>
                                 </button>
                                </h5>
                            </div>
                        </div>
                            <hr class="my-2">
                    </div>
                    
                </div>
            </div>
            <!--   Sezione Footer: button per la scelta di aggiunzioni-->
            <div class="card-footer back-green mt-2">
                <div class="row">
                    <div class="col-md-7 offset-md-0 col-10 offset-1">
                        <p class="back-green">
                            <!-- Button trigger modal -->
                            <button 
                                type="button" class="btn btn-outline-success mt-3" 
                                data-bs-toggle="modal" 
                                data-bs-target="#staticBackdrop"
                                v-show="product_ingredients.length"
                                >Aggiungi ingredineti al tuo piatto!
                            </button>
                        </p>
                    </div>
                    <div class="col-md-5 offset-md-0 col-10 offset-1 text-center ">
                        <button 
                            class="btn btn-outline-success mt-3"
                            @click="addToCart"
                            >€ {{ pizzaTotalPrice.toFixed(2) }} - Aggiungi all'ordine
                        </button>
                    </div>
                </div>
                <!-- Modal -->
                <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-scrollable">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title back-green" id="staticBackdropLabel">Scegli ingredienti da aggiungere al tuo piatto</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row back-green">
                            <div class="col-12 back-green" v-for="ingredient in ingredients">
                                <h5 
                                    class="back-green"
                                    >{{ ingredient.name }}  €{{ ingredient.price }}
                                    <button 
                                        class="btn btn-outline-success ms-5"
                                        @click="addAddition(ingredient)"
                                        >
                                        <i class="fa-solid fa-circle-plus text-info"></i>
                                    </button>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Chiudi</button>
                    </div>
                    </div>
                </div>
                </div>

            </div>
        </div>
        <div style="height: 100px;"></div>

        <PizzaNav 
            class="mt-2"
            :categories="categories"
        />
    </div>
</template>

<script>
    import gsap from 'gsap';
    import axios from 'axios';
    import NavbarHome from '@/components/NavbarHome.vue';
    import PizzaNav from '@/components/PizzaNav.vue';

    export default{
        name: "FoodProduct",
        data(){
            return{
                categories: [],
                product : {},
                ingredients: [],
                product_ingredients: [],
                withList: [],
                withoutList: [],
               
            }
        },
        components:{
        PizzaNav,
        NavbarHome
        },
        beforeCreate(){
            this.$store.commit('inizializeStore')
            },
        created(){
            this.getProduct();
            
        },
        mounted(){
            this.getCategories()
            const tl = gsap.timeline({ paused: true});
            tl.from(".head1", { x:'+400', rotateY: 360, autoAlpha:0, duration:0.8});
            tl.from(".box", { x:'+300', scale:0.2, autoAlpha:0, duration:1});
            tl.from(".head2", { x:'+200', rotateY: 360, scale:0.2, autoAlpha:0, duration:0.8});
            tl.play()
        },
        computed:{
            pizzaTotalPrice(){
                return this.withList.reduce((acc, curVal) => {
                    return acc + curVal.addPrice 
                }, 0) + parseFloat(this.product.price)
            },
        },
        methods:{
            async getProduct(){
                const category_slug = this.$route.params.category_slug;
                const product_slug = this.$route.params.product_slug;

                await axios.get(`/api/v1/product/${category_slug}/${product_slug}`)
                    .then(response =>{
                        this.product = response.data 
                        document.title = this.product.name + '| Virtual Pizza' 
                    })
                    .catch(error =>{
                        console.log(error)
                    });
                    for (let i=0;i < this.product.ingredients.length;i++){
                        const ingredient= this.product.ingredients[i]
                        const obj = {
                            additionName : ingredient.name,
                            additionPrice : ingredient.price
                        } 
                        this.product_ingredients.push(obj)
                    }
            },
            async getCategories(){
                
                   await axios.get('/api/v1/ingredients/').then(response=>{
                        this.ingredients = response.data
                    })
                    .catch(error =>{
                        console.log(error)
                    });
            },
            //funzione che aggiunge un igrediente in più
            addAddition(ingredient){
               const objt = {
                addName : ingredient.name,
                addPrice : parseFloat(ingredient.price)
               }
                this.withList.push(objt);
            },
            //funzione che rimuove un ingrediente in più precedentemente scelto
            removeAddition(item){
                const additionIndex = this.withList.indexOf(item)
                this.withList.splice(additionIndex, 1)
            },
            //funzione che toglie un ingrediente originale della pizza 
            removeIngredient(item){
                const exist = this.withoutList.filter(i=> i.additionName === item.additionName)
                if(! exist.length){
                    this.withoutList.push(item)
                }
            },
            //funzione che annulla la rimozione di un ingrediente originale della pizza
            addIngredient(item){
                const index = this.withoutList.indexOf(item)
                this.withoutList.splice(index, 1)
            },
            //aggiungi ala carrello
            addToCart(){
                const item ={
                    product: this.product,
                    withouts: this.withoutList, //additionName 
                    withs: this.withList, //addName , addPrice
                    itemPrice: this.pizzaTotalPrice
                }
                this.$store.commit('addToCart', item)
                this.$router.push('/virtual-pizza')

            }
        }
       
    }
</script>

<style scoped>
.box{
    border-radius: 15px;
    -webkit-box-shadow: -3px 7px 11px 0px rgba(120,110,110,0.47); 
    box-shadow: -3px 7px 11px 0px rgba(120,110,110,0.47);
}

.card{
    background: none;
}

.card-header, .card-body, .card-footer{
    border-radius: 20px;
    border: solid 3px rgba(145, 168, 126, 0.8);
}
</style>