<template>
    <NavbarHome/>
    <transition appear name="fade" >
    <div class="show container-fluid">
        <div class="divider"></div>
        
        <div class="container">
            <div class="row">
                <router-link to="/social-app">
                    <div class="col-4">
                        <h1 class="logo">Social-App</h1>
                    </div>
                </router-link>
            </div>
            <div class="row d-flex align-items-end">
                <div class="col-md-4 pe-0">
                    <h2 class="text-start title mb-0"><strong>{{ user.Name }}</strong></h2>
                    <hr class="me-md-0 me-3" style="height: 2px; background-color: black; margin: 0;">
                </div>
            
                <div class="col-md-5 px-md-0">
                    <p class="text-start text-muted mt-2 mb-1">{{ user.Role }}</p>
                    <hr class="d-md-block d-none" style="height: 2px; background-color: black; margin: 0;">
                </div>
                <div class="col-md-3 mt-2 ps-md-0 d-md-block d-flex">
                    <h6 v-if="(user['Is requestable'] === true)" @click="sendRequest()" class="justify-content-start btn btn-sm rounded-pill request-button">Send Request</h6>
                    <p v-else></p>
                    
                    <hr class="d-md-block d-none" style="height: 2px; background-color: black; margin: 0;">
                </div>
            </div>
        </div>
        

        <!-- <hr class="mb-3" style="height: 2px; background-color: black; margin: 0;"> -->
        <div class="container">
            <div class="row mt-4">
                <div class="col-md-3 mb-3">
                    <img class="img-fluid profile-img" :src="userImage.url">
                </div>
                <div class="col-md-9">
                    <div class="row pb-0">
                        <div class="col-1">
                            <img src="../assets/Quote-1.svg" alt="">
                        </div>
                        <div class="col-md-5 col-10 card-body ps-md-1 ps-3 pe-0">
                            <h6 class="text-start name"><strong>{{ user.Quote }}</strong></h6>
                        </div>
                        <div class="col-1 px-0 d-flex align-items-end">
                            <img src="../assets/Quote-2.svg" alt="">
                        </div>
                        <div class="col-md-5 col-12"></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-1"></div>
                        <div class="col-md-10 ps-md-1 ps-3 pe-0">
                            <p class="text-start">{{user.Bio}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="divider"></div>
        <div class="divider"></div>
    </div>
    </transition>
</template>

<script>
import axios from 'axios';
import NavbarHome from '../components/NavbarHome.vue'

export default{
    name: 'ShowView',
    components:{
        NavbarHome
    },
    data(){
        return{
            user: {},
            userImage: {},
            user_id: '',
            api_token: 'keyEC9F0usYdVoWzg'
        }
    },
    mounted(){
        this.getUser()
    },
    methods:{
        getUser(){
            const user_id = this.$route.params.user_id;
            axios.get(`https://api.airtable.com/v0/app7cLoZ4xMOrQvKR/People/${user_id}?api_key=`+ this.api_token)
            .then(response =>{
                    this.user = response.data.fields
                    this.userImage = response.data.fields['Profile image'][0]
                    this.user_id = response.data.id
                    console.log(response.data)
                    })
                    
                    .catch(error =>{
                                    console.log(error)
                                });
            document.title = "RV - Social-App"
        },
        sendRequest(){
            axios.request({
                method: 'POST',
                url: 'https://api.airtable.com/v0/app7cLoZ4xMOrQvKR/Friend%20request',
                headers: {
                    Authorization: `Bearer ${this.api_token}`,
                        'Content-Type': 'application/json'

                },
                data: {
                    "fields": {
                                "From": "Roberto",
                                "To": [
                                    this.user_id
                                ]
                            }
                },
            })
            .then(response=>{
                console.log(response.data)
            })
            .catch(error =>{
                                    console.log(error)
                                });
        }
        
    }
}
</script>

<style scoped>
.show{
    background: linear-gradient(90deg, rgb(231, 204, 150) 0%, rgb(216, 176, 73) 94%);
    font-family: 'Kdam Thmor Pro', sans-serif;
    color: black;
}
.request-button{
    border: solid 2px black;
    background-color: #8dcc7b;
    font-weight: 700;
}
.profile-img{
    border-radius: 5px;
}

.fade-enter-from{
  opacity: 0;
  transform: translateX(50px);
}
.fade-enter-active{
  transition: all 1.5s ease-out;
}
.fade-leave-to{
  opacity: 0;
  transform: translateX(-100px);
}
.fade-leave-active{
  transition: all 0.6s ease-out;
}
.logo{
    font-family: 'Bungee Inline', cursive;
    color: #664508;
}
</style>