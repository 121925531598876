<template>
    
    <div class="contact container">
        <NavbarHome class="my-3"/>
        <div class="divider"></div>
        <div class="row">
            <div class="col-md-6 offset-md-3 col-10 offset-1">
                <transition appear name="fall" mode="bounce-in">
                    <div>
                        <h1 class="text-start">Contatti:</h1> <br><br>
                        <h4 style="line-height: 45px">Scrivimi se hai una proposta da farmi o per qualsiasi informazione</h4> <br><br><br>
                    </div>
                </transition>
            </div>
            <div class="col-md-6 offset-md-3">
                <transition-group 
                    appear
                    tag="div"
                    class="row d-flex justify-content-around mb-4"
                    @before-enter="beforeEnter"
                    @enter="enter"
                    >
                    <div v-for="(icon, index) in icons" :key="icon.name" :data-index="index" class="col-lg-4 link col-5">
                        <a :href="icon.link">
                        <div class="card contact-box  m-2 text-center">
                            <i :style="icon.color" :class="icon.name" class="mt-4 mb-3"></i>
                            
                                <div class="icon-text mb-2">{{ icon.text }}</div>
                            
                        </div>
                        </a>
                    </div>
                </transition-group>
            </div>
        </div>

    </div>
</template>

<script>
import { ref } from 'vue'
import gsap from 'gsap'
import NavbarHome from '@/components/NavbarHome.vue';
export default{
    name: 'ContactView',
    setup() {
      const icons = ref([
        
        { name: 'fa-solid fa-at fa-2xl', text: 'Email', 
            color: '#d9bfb3;', link: 'mailto: robertovaccaro5@gmail.com'},
        { name: 'fa-brands fa-linkedin fa-2xl', text: 'Linkedin', 
            color: 'color: #d9bfb3;', link: 'https://www.linkedin.com/in/roberto-vaccaro-463751251/'},
      ])
  
      return { icons }
    },
    mounted(){
      document.title = "RV - Contatti"
    },
    components:{
        NavbarHome
    },
    methods:{
      beforeEnter(el){
        el.style.opacity = 0;
        el.style.transform = 'translateY(300px)';
  
      },
      enter(el, done){
        gsap.to(el, {
          opacity: 1,
          y:0,
          rotateX: 360,
          duration: 1.5,
          onComplete: done,
          delay: el.dataset.index * 0.4
        })
      }
    }
}

</script>

<style>
a{
    text-decoration: none;
}
.contact{
    font-family: 'Kdam Thmor Pro', sans-serif;
}
.contact-box{
   background: none;
   border: solid 3px #64090b;
   border-radius: 30px;
}
.icon-text{
    color: #d9bfb3;
}

.fall-enter-from, .switch-leave-to{
    opacity: 0;
    transform: translateY(-120px);
    
  }

  .fall-enter-active{
    transition: all 2.5s ease;
  }
  .fall-leave-active{
    transition: all 1s ease;
  }

.link:hover {
  animation: wobble 0.5s;
}
@keyframes wobble {
  0% { transform: translateX(0px); }
  20% { transform: translateX(+20px);}
  40% { transform: translateX(-15px); }
  60% { transform: translateX(+10px); }
  80% { transform: translateX(-5px); }
  100% { transform: translateX(0px); }
}
</style>