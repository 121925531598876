<template>
    <RouterLink :to="product.get_absolute_url">
        <div class="card">
            <div class="card-header back-green my-2">
                    <div class="row">
                        <div class="col">
                            <p class=" back-green"><strong>{{ product.name}}</strong></p>
                            <p class="card-title back-green">€ {{ product.price }}</p> 
                        </div>
                    </div>
                    <div class="row">
                            <h5 class="text-mutedback-green">{{ product.ingredients_list }}</h5>
                    </div>
            </div>
                <img class="card-img-top box" v-bind:src="product.imageURL">
        </div>
    </RouterLink>
</template>

<script>

export default{
    name: "PizzaProduct",
    props:{
        product: Object
    }
}

</script>

<style scoped>
.box{
    border-radius: 15px;
    -webkit-box-shadow: -3px 7px 11px 0px rgba(120,110,110,0.47); 
    box-shadow: -3px 7px 11px 0px rgba(120,110,110,0.47);
}
.card{
    background: none;
}
.card-header{
    border-radius: 20px;
    border: solid 3px rgba(145, 168, 126, 0.8);
}
</style>