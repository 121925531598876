<template>
    <div class="signup-page">
        <NavbarHome/>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <h1>Registrati</h1>
                <div class="module-border-wrap">
                    <div class="module">
                        <form @submit.prevent="submitForm">
                            <div class="mb-3">
                                <label class="form-label">Username</label>
                                <input v-model="username" class="form-control border-danger">
                                
                            </div>
                            <div class="mb-3">
                                <label for="exampleInputPassword1" class="form-label">Password</label>
                                <input v-model="password" type="password" class="form-control border-danger" id="exampleInputPassword1">
                            </div>
                            <div class="mb-3">
                                <label for="exampleInputPassword1" class="form-label">Ripeti Password</label>
                                <input v-model="password2" type="password" class="form-control border-danger" id="exampleInputPassword1">
                            </div>
                            <div v-if="errors.length" class="alert alert-danger">
                                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                            </div>
                            
                            <button type="submit" class="btn btn-outline-danger">Iscriviti</button>
                            Oppure <router-link to="/log-in">Effettua il Login!</router-link>
                        </form>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import NavbarHome from "@/components/NavbarHome.vue";

export default{
    name: "SignUp",
    data(){
        return{
            username: '',
            password: '',
            password2: '',
            errors: []
        }
    },
    components:{
        NavbarHome
    },
    methods:{
        submitForm(){
            this.errors = []
            if (this.username === '') {
                this.errors.push('Inserisci un username!')
            }
            if (this.password === '') {
                this.errors.push('La password è troppo breve!')
            }
            if (this.password !== this.password2) {
                this.errors.push('Le password non combaciano!')
            }
            if (!this.errors.length) {
                const formData = {
                    username: this.username,
                    password: this.password
                }
                axios
                    .post("/api/v1/users/", formData)
                    .then(response=>{
                        alert("Account creato correttamente")
                        this.$router.push('/log-in')
                    })
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                            console.log(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            this.errors.push('Qualcosa è andato storto :( per favore riprova')
                            
                            console.log(JSON.stringify(error))
                        }
                    })
            }
        }
    }
}
</script>

<style scoped>

.module-border-wrap {
  /* max-width: 250px; */
  padding: 1rem;
  position: relative;
  background: linear-gradient(to right, red, rgb(212, 224, 213));
  border-radius: 24px;
  padding: 3px;
  margin-bottom: 10px;
}

.module {
  background: rgb(227, 151, 84);
  color: black;
  border-radius: 24px;
  padding: 2rem;
  height: 480px;
}

</style>