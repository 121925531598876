<template>
<NavbarHome class="mb-3"/>
<div class="my-projects container">
  <div class="divider"></div>
  <transition-group 
        appear
        tag="div"
        class="row my-3"
        @before-enter="beforeEnter"
        @enter="enter"
      >
      <div 
        v-for="(project, index) in projects" 
        :key="project.id"
        :data-index="index" 
        class="col-12 my-2">
        <a style="text-decoration: none" :href="project.path">
          <h2 
            :id="project.name.substring(0, 2)" 
            @mouseover="scale('#'+project.name.substring(0, 2))"
            @mouseleave="scaleOut('#'+project.name.substring(0, 2))" 
            class="name">{{ project.name }}</h2>
          <p class="red">{{ project.description }}</p>
        </a>
      </div>
    </transition-group>
  </div>



</template>

<script>
import NavbarHome from "@/components/NavbarHome.vue";
import axios from 'axios';
import gsap from 'gsap';
export default{
    name: 'MyProjects',
    data(){
    return{
        projects: []
        }
    },
    components: {
            NavbarHome
        },
        mounted(){
            this.getProjects()
            document.title = "RV - MyProjects"
        },
    methods:{
      getProjects(){
        axios.get('/api/v1/projects/')
        .then(response =>{
          this.projects = response.data
        }
        )
        .catch(error =>{
                        console.log(error)
                    });
      },
      beforeEnter(el){
      el.style.opacity = 0;
      el.style.transform = 'scale(0.5)';
      el.style.transform = 'translateX(100px)';

      },
      enter(el, done){
        gsap.to(el, {
          opacity: 1,
          scale:  1,
          x:0,
          duration: 1.2,
          onComplete: done,
          delay: el.dataset.index * 0.4
        })
      },
      scale(param){
        gsap.to(param, {
        x: +5,
        y: -5,
        scale: 0.9,
        duration: 0.5,
        color: '#997c84'
        })
    },
    scaleOut(param){
        gsap.to(param, {
        x: 0,
        y: 0,
        scale: 1,
        duration: 0.8,
        color: 'white'
        })
    }
    }
}
</script>

<style scoped>
h1, h2, h3, h4, h5, h6, p{
  color: #d9bfb3;
  background-color: black;
}
.red{
  color: #bd041f;
}

</style>