<template>
 <div class="container-fluid">
  <router-view/>
 </div>
  
</template>

<script>
import NavbarHome from './components/NavbarHome.vue';
export default{
  data(){
    return{
      
    }
  },
  components:{
    NavbarHome
  }
  
}

</script>

<style lang="scss">
// #bd041f #1d1010 #970a1b #d9bfb3 #9d928e #64090b #68564f #997c84 #5f444c
.divider{
  height: 80px;
}
body{
  color: #d9bfb3;
  background-color: black;
}
.card{
  color: rgb(221, 224, 206);
  background-color: #202020;
}

// #app {
//   font-family: 'Cabin Sketch', cursive;
// }
.back-green{
        background-color: rgb(233, 238, 229);
        color: black;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
    .red{
  color: #bd041f;
}
</style>
