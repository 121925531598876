<template>
    <NavbarHome class="my-3"/>
    <div class="pizza-cart container">
        <div class="divider"></div>
        
        <h1 class="">Il tuo ordine:</h1>
        <table class="table back-green border border-3 rounded-3 border-success mt-3">
            <thead>
                <tr>
                <th scope="col">Ordinazione</th>
                <th></th>
                <th scope="col">Immagine</th>
                <th scope="col">Descrizione</th>
                <th scope="col">Prezzo</th>
                </tr>
            </thead>
            
            <tbody>
                <tr v-for="item in cart.items">
                <td><h4 class="back-green table-row">{{ item.product.name }}</h4></td>
                <td>
                    <button @click="removeFromCart(item)">
                        <i class="fa-solid fa-trash-can text-danger"></i>
                    </button>
                </td>
                <td><img class="cart-img" v-bind:src="item.product.imageURL" alt=""></td>
                <td> <p 
                        v-show="item.withs.length"
                        class="back-green text-center table-row"
                        v-for="add in item.withs"
                        >Con: {{ add.addName }} € {{ add.addPrice }}
                    </p>
                    <p 
                        class="back-green text-center table-row"
                        v-for="min in item.withouts"
                        v-show="item.withouts.length"
                        >Senza: {{ min.additionName }}

                    </p>
                </td>
                <td>€ {{ item.itemPrice }}</td>
                </tr>
            </tbody>
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Totale</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th><h5 class="back-green">€ {{ cartTotalPrice }}</h5></th>
                    <th>
                        <!-- Button trigger modal -->
                        <button type="button" class="btn btn-outline-success" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            <i class="fa-solid fa-utensils"></i>
                        Invia Ordinazione
                        </button>

                        <!-- Modal -->
                        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h3 
                                    style="background: white; color: black;"
                                    class="modal-title text-success" 
                                    id="staticBackdropLabel"
                                    ><strong>Ordine ricevuto! Mettiti comodo, stiamo per servirti!</strong> 
                                </h3>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <h5 
                                    class="bg-light "
                                    v-for="item in cart.items"
                                    ><strong>{{ item.product.name }} </strong>
                                    
                                    <span 
                                        class="text-dark"
                                        v-show="item.withs.length"
                                        > <u> con: </u><i
                                                 v-for="w in item.withs"
                                                    > {{ w.addName }} -
                                                </i> 
                                    </span>
                                    <span
                                        class="text-dark"
                                        v-show="item.withouts.length"
                                        > <u> senza: </u><span
                                                    v-for="wo in item.withouts"
                                                    > {{ wo.additionName }}

                                                 </span>
                                    </span>
                                </h5>
                            </div>
                            <div class="modal-footer">
                                <button 
                                    @click="clearCart()"
                                    type="button" 
                                    class="btn btn-outline-dark" 
                                    data-bs-dismiss="modal"
                                    >Close
                                </button>
                                
                            </div>
                            </div>
                        </div>
                        </div>
                    </th>
                </tr>
            </tbody>
        </table>
        <br><br><br>
        <PizzaNav 
            class="mt-5"
            :categories="categories"
        />
    </div>
</template>

<script>
import NavbarHome from '@/components/NavbarHome.vue';
import axios from 'axios';
import PizzaNav from '@/components/PizzaNav.vue';

export default{
    name: "PizzaCart",
    data(){
            return{
                cart:{
                    items: []
                },
                categories: []
            }
        },
    beforeCreate(){
        this.$store.commit('inizializeStore')
        },
    mounted(){
        document.title = "RV - Virtual-Pizza-Carrello"
        this.cart = this.$store.state.cart
        this.getCategories()
    },
    components:{
        PizzaNav,
        NavbarHome
    },
    computed:{
        cartTotalPrice(){
                return this.cart.items.reduce((acc, curVal) => {
                    return acc += curVal.itemPrice 
                }, 0)
            }
    },
    methods:{
        async getCategories(){
                await axios.get('/api/v1/categories/')
                    .then(response =>{
                        this.categories = response.data
                    })
                    .catch(error =>{
                        console.log(error)
                    });
                },
        removeFromCart(item){
                const index = this.cart.items.indexOf(item)
                this.cart.items.splice(index, 1)
                this.$store.commit('removeFromCart', this.cart)
            },
        clearCart(){
            this.$store.commit('clearCart')
            this.$router.push('/virtual-pizza')
        }
    }
}

</script>

<style scoped>

h1, h2, h3, h4, h5, h6, p, a, table{
        color: rgb(74, 141, 113);
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
.cart-img{
    height: 80px;
}

</style>