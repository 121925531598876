<template>
  
  <transition appear name="switch">
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 ">
          <div class="divider"> </div>
            <div class="row">
              <div class="col-12">
                <h2 class="text-center f1 my-md-2"> Mi chiamo </h2>
              </div>
              <div class="d-flex justify-content-center my-2">
                  <h1 id="name" class="l1 ">R</h1>
                  <h1 id="name" class="l2 ">o</h1>
                  <h1 id="name" class="l3 ">b</h1>
                  <h1 id="name" class="l4 ">e</h1>
                  <h1 id="name" class="l5 ">r</h1>
                  <h1 id="name" class="l6 ">t</h1>
                  <h1 id="name" class="l7 ">o</h1>
              </div>
              <div class="d-flex justify-content-center my-2">
                  <h1 id="name" class="l8 ">V</h1>
                  <h1 id="name" class="l9 ">a</h1>
                  <h1 id="name" class="l10 ">c</h1>
                  <h1 id="name" class="l11 ">c</h1>
                  <h1 id="name" class="l12 ">a</h1>
                  <h1 id="name" class="l13 ">r</h1>
                  <h1 id="name" class="l14 ">o</h1>
              </div>
              <div class="col-12 my-2">
                <h2 class="text-center f2">... sono uno sviluppatore</h2>
              </div>
            </div>
        </div>

        <div class="col-lg-5 offset-lg-1 my-0">
           <div class="divider"></div>
            <p 
              class="skil-one text-start mb-4" style="letter-spacing: 3px;">
              ~<span class="red"> Django Rest Framwork</span>
            </p>
            <p 
              class="skil-two text-center mb-4" style="letter-spacing: 3px;">
              ~ <span class="red"> VueJS</span>
            </p>
            <p 
              class="skil-three text-start mb-4" style="letter-spacing: 3px;">
              ~ <span class="red"> Gsap</span>
            </p>
            <p 
              class="skil-four text-center mb-4" style="letter-spacing: 3px;">
              ~ <span class="red"> Bootstrap5 css</span>
            </p>
            <p 
              class="skil-five text-start mb-4" style="letter-spacing: 3px;">
              ~ <span class="red"> Git</span>
            </p>
            <p 
              class="skil-six text-center mb-1" style="letter-spacing: 3px;">
              ~ <span class="red"> Deployment on AWS o altri servizi</span>
            </p>
            <div class="row d-flex flex-column justify-content-center ms-md-5">
              <RouterLink style="text-decoration: none" to="/my-projects">
                <div class="col-md-6 offset-md-2 my-3 seven">
                  <h3 @mouseleave="scaleOut('.routelink7')" @mouseover="scale('.routelink7')" style="letter-spacing: 5px;" class="text-center routelink7">
                  <strong style="font-size:50px">Progetti</strong>
                  </h3>
                </div>
              </RouterLink>
              <div class="col-md-8">
                
              </div>
              <RouterLink style="text-decoration: none" to="/about">
                <div class="col-md-6 offset-md-4 my-3 eight">
                  <h3 @mouseleave="scaleOut('.routelink8')" @mouseover="scale('.routelink8')" class="text-center routelink8" style="letter-spacing: 5px;">
                  <strong style="font-size:50px">About</strong> 
                  </h3>
                </div>
              </RouterLink>
              <div class="col-md-8">
                
              </div>
              <RouterLink style="text-decoration: none" to="/contatti">
                <div class="col-md-6 offset-md-6 my-3 nine">
                  <h3 @mouseleave="scaleOut('.routelink9')" @mouseover="scale('.routelink9')" class="text-center routelink9" style="letter-spacing: 5px;">
                  <strong style="font-size:50px">Contatti</strong> 
                  </h3>
                </div>
              </RouterLink>
              <div class="col-md-8">
                
              </div>
            </div>
        </div>
     </div>
     
        
    </div>
    <div class="divider"></div>
    <div class="divider"></div>
  </div>
  </transition>
</template>

<script>
import gsap from 'gsap'
import { ref } from 'vue'
import NavbarHome from "@/components/NavbarHome.vue";
// @ is an alias to /src

export default {
  name: 'HomeView',
  
  mounted(){
    document.title = "RV - HomePage"
    const tl = gsap.timeline({ paused: true});
    const tl2 = gsap.timeline({ delay: 0.3, paused: true});
    
    tl.from(".skil-zero", { y:'+400', scale:0.2, autoAlpha:0, duration:0.8});
    tl.from(".skil-one", { x:'-200',  scale:0.5, autoAlpha:0, rotateY: 360, duration:0.5});
    tl.from(".skil-two", { x:'+200',  scale:0.5, autoAlpha:0, duration:0.5});
    tl.from(".skil-three", { x:'-200', scale:0.5, autoAlpha:0, duration:0.5});
    tl.from(".skil-four", { x:'+200', scale:0.5, autoAlpha:0, duration:0.5});
    tl.from(".skil-five", { x:'-200', scale:0.5, autoAlpha:0, duration:0.5});
    tl.from(".skil-six", { x:'+200', scale:0.5, autoAlpha:0, rotateY: 360, duration:1});
    tl.from(".seven", { scale:0.5, autoAlpha:0, duration:0.8, delay:0.2});
    tl.from(".eight", { scale:0.5, autoAlpha:0, duration:0.8});
    tl.from(".nine", { scale:0.5, autoAlpha:0, duration:0.8});
    tl.play()
    tl2.from(".f1", { x:'+200', scale:0.5, autoAlpha:0, rotateY: 360, duration:0.5});
    tl2.from(".l1", { autoAlpha:0, rotateX: 360, duration:0.2});
    tl2.from(".l2", { autoAlpha:0, rotateX: 360, duration:0.2});
    tl2.from(".l3", { autoAlpha:0, rotateX: 360, duration:0.2});
    tl2.from(".l4", { autoAlpha:0, rotateX: 360, duration:0.2});
    tl2.from(".l5", { autoAlpha:0, rotateX: 360, duration:0.2});
    tl2.from(".l6", { autoAlpha:0, rotateX: 360, duration:0.2});
    tl2.from(".l7", { autoAlpha:0, rotateX: 360, duration:0.2});
    tl2.from(".l8", { autoAlpha:0, rotateX: 360, duration:0.2});
    tl2.from(".l9", { autoAlpha:0, rotateX: 360, duration:0.2});
    tl2.from(".l10", { autoAlpha:0, rotateX: 360, duration:0.2});
    tl2.from(".l11", { autoAlpha:0, rotateX: 360, duration:0.2});
    tl2.from(".l12", { autoAlpha:0, rotateX: 360, duration:0.2});
    tl2.from(".l13", { autoAlpha:0, rotateX: 360, duration:0.2});
    tl2.from(".l14", { autoAlpha:0, rotateX: 360, duration:0.2});
    tl2.from(".f2", { x:'+200', scale:0.5, autoAlpha:0, rotateY: 360, duration:0.5});
    tl2.play()
    
  },
  methods:{
    scale(param){
        gsap.to(param, {
        scale: 1.2,
        x: 50,
        y: -15,
        duration: 0.5,
        color: '#970a1b'
        })
    },
    scaleOut(param){
        gsap.to(param, {
        scale:  1,
        x: 0,
        y: 0,
        duration: 0.8,
        color: 'white'
        })
    }

    },
  components: {
    NavbarHome
},
}
</script>

<style scoped>
h1, h2, h3, h4, h5, h6, p{
  color: #d9bfb3;
  background: none;
}
/* switch transitions */
.switch-enter-from, .switch-leave-to{
    opacity: 0;
    transform: translate(70px);
  }

  .switch-enter-active{
    transition: all 2.5s ease;
  }
  .switch-leave-active{
    transition: all 1s ease;
  }
.link{
  border-radius: 15px;
  box-shadow: 0 0 0 3px #c3161633,
  0 0 0 7px #c6464622,
  0 0 15px #911313;
}
#name{
  font-size: 70px;
  font-family: 'Bungee Inline', cursive;
  color: #970a1b;
}

.f1, .f2{
  font-size: 40px;
}
.home{
  background: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url('~@/assets/me-10.jpeg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-position-y: 10%;
  background-size:contain;
  font-family: 'Kdam Thmor Pro', sans-serif;
}


.seven {
  animation: wobble 2s infinite;
}
@keyframes wobble {
  0% { transform: translateY(0px);}
  40% { transform: translateY(-60);}
  50% { transform: translateX(8px);}
  60% { transform: translateX(-8px); }
  70% { transform: translateX(4px); }
  80% { transform: translateX(-4px); }
  100% { transform: translateX(0); } 
}
.eight {
  animation: wobble2 2s infinite;
}
@keyframes wobble2 {
  0% { transform: translateY(0px);}
  47% { transform: translateY(-60);}
  57% { transform: translateX(8px);}
  67% { transform: translateX(-8px); }
  78% { transform: translateX(4px); }
  92% { transform: translateX(-4px); }
  100% { transform: translateX(0); } 
}
.nine {
  animation: wobble3 2s infinite;
}
@keyframes wobble3 {
  0% { transform: translateY(0px);}
  55% { transform: translateY(-60);}
  65% { transform: translateX(8px);}
  75% { transform: translateX(-8px); }
  85% { transform: translateX(4px); }
  95% { transform: translateX(-4px); }
  100% { transform: translateX(0); } 
}


</style>
