<template>
  <div class="virtual-pizzeria-nav">
    <nav class="navbar navbar-dark fixed-bottom navbar-expand-lg ">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">Tavolo 6</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav m-auto mb-2 mb-lg-0">
            <li class="nav-item mx-5">
              <a class="nav-link active" aria-current="page" href="#">Chiama il servizio</a>
            </li>
            <li class="nav-item mx-5">
              <router-link to="/virtual-pizza" class="btn btn-outline-success">Home</router-link>
            </li>
            <li class="nav-item dropdown mx-5">
              <div class="btn-group dropup">
                <button type="button" class="btn btn-outline-success dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  Menù
                </button>
                <ul class="dropdown-menu">
                  <li 
                    
                    v-for="category in categories"
                    > 
                    <router-link :to="category.get_absolute_url">
                      <strong class="ms-2">{{ category.name}}</strong>
                    </router-link>
                    
                  </li>
                </ul>
              </div>
            </li>
            
          </ul>
          <router-link to="/virtualPizza-cart">
            <span class="btn btn-outline-success nav-item me-5">
              Ordine <i class="fas fa-shopping-cart">({{ cartTotalLenght }})</i>
            </span>
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
    export default{
        name: "PizzaNav",
        data(){
          return{
            cart: {
              items: []
            }
          }
        },
        props:{
            categories: Array
        },
        watch: {
        $route(to, from) {
                if (to.name === 'Category') {
                    this.getCategory()
                }
            }
        },
        mounted(){
          this.cart = this.$store.state.cart
        },
        computed:{
          cartTotalLenght(){
            let totalLenght = 0
            for(let i = 0; i < this.cart.items.length; i++){
            totalLenght += 1
          }
          return totalLenght
          }
        },
    }
</script>
<style scoped>
   h1, h2, h3, h4, h5, h6, p, a, li{
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
    .navbar{
      background: rgb(2, 22, 2);
      border-radius: 4px;
      color: rgb(221, 224, 206);
     }
     a {
  text-decoration: none;
  color: rgb(221, 224, 206);
}
a:hover{
  background-color: rgb(55, 135, 94);
}
.dropdown-menu{
  background: rgb(2, 22, 2);
}
</style>