<template>
    <NavbarHome class="my-3"/>
    <div class="category-page">
        <div class="container-fluid">
            <div class="row">
                <div class="col-4 offset-1">
                    <hr>
                    
                    <h1 class="ms-5">{{ category.name }}:</h1>
                    <hr>
                </div>
            </div>
            
            <div class="row">
            <div class="col-md-1"></div>
            <div class="col-11">
                <transition-group 
                    appear
                    tag="div"
                    class="row"
                    @before-enter="beforeEnter"
                    @enter="enter"
                >
                    <div 
                        v-for="(product, index) in category.products" 
                        :data-index="index" 
                        :key="product.id"
                        class="col-md-5 mx-4 mb-3">
                        <PizzaProduct
                            :key="product.id"
                            :product="product"/>
                    </div>
                    <hr>
                </transition-group>
            </div>
            
        </div>
        </div>
        <br><br>
        <PizzaNav 
            class="mt-2"
            :categories="categories"
        />
    </div>
</template>

<script>
import NavbarHome from '@/components/NavbarHome.vue';
import axios from 'axios'
import gsap from 'gsap';
import PizzaNav from '@/components/PizzaNav.vue';
import PizzaProduct from '@/components/PizzaProduct.vue'

export default{
    name: "CategoryPage",
    components:{
        PizzaProduct,
        PizzaNav,
        NavbarHome
    },
    data(){
            return{
                category:{
                    products: []
            },
            categories: []
            }
        },
        mounted(){
            this.getCategory()
        },
        watch: {
        $route(to, from) {
                if (to.name === 'CategoryPage') {
                    this.getCategory()
                }
            }
        },
       
        methods:{
            async getCategory(){
                const category_slug = this.$route.params.category_slug;
                await axios.get(`/api/v1/category/${category_slug}`)
                .then(response =>{
                        this.category = response.data
                        document.title = this.category.name + '| Virtual Pizzeria'
                    })
                    .catch(error =>{
                        console.log(error)
                    });
                axios.get('/api/v1/categories/')
                    .then(response =>{
                        this.categories = response.data
                    })
                    .catch(error =>{
                        console.log(error)
                    })
            },
            beforeEnter(el){
                el.style.opacity = 0;
                el.style.transform = 'scale(0.5)';
                el.style.transform = 'translateY(200px)';

                },
            enter(el, done){
                gsap.to(el, {
                opacity: 1,
                scale:  1,
                y:0,
                duration: 1.2,
                onComplete: done,
                delay: el.dataset.index * 0.4
                })
            },
        }
}

</script>



<style scoped>
    h1, h2, h3, h4, h5, h6, p, a, input{
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
  
   
    .title{
        color: rgb(16, 111, 59);
    }
    a {
  text-decoration: none;
}
  
</style>