<template>

<NavbarHome class="mt-2 ms-0"/>
<transition appear name="fade">
<div class="weather-app">
    <div class="container mt-3">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <div class="card">
                  <img src="../assets/meteo-5.jpg" class="card-img main-box" alt="...">
                  <div class="card-img-overlay">
                      <div class="weather-box">
                          <h1 class="weather mt-3">Meteo app</h1>
                      </div>
                      <input 
                          class="form-control rounded-pill me-2" 
                          type="search" placeholder="Cerca..." 
                          aria-label="Search" 
                          v-model="query"
                          @keypress="fetchWeather">
                      <div v-if="typeof weather.main != 'undefined'">
                          <div class="location-box" >
                              <div class="location">{{ weather.name }}, {{ weather.sys.country }}</div>
                              <div class="date"> {{ dateBuilder() }}</div>
                          </div>
                          <div class="weather-box">
                              <div class="temp">{{ Math.round(weather.main.temp) }}°c</div>
                              <div class="weather">{{ weather.weather[0].main }}</div>
                              <div class="weather">Vento: {{ weather.wind.speed }}</div>
                              <div>{{ weather.description }}</div>
                          </div>
                      </div>
                      
                  </div>
              </div>
            </div>
        </div>
    </div>
</div>
</transition>

</template>

<script>
import NavbarHome from "@/components/NavbarHome.vue";

export default{
    
    name: "Weather",
    components:{
        NavbarHome
    },
    mounted(){
      document.title = "RV - Meteo-app"
    },
    data(){
        return{
            apiKey: 'd42ce80f32da506d177641872e9d31d6',
            url_base: 'https://api.openweathermap.org/data/2.5/',
            query: '',
            weather: {}
        }
    },
    methods: {
    fetchWeather (e) {
      if (e.key == "Enter") {
        fetch(`${this.url_base}weather?q=${this.query}&units=metric&lang=it&APPID=d42ce80f32da506d177641872e9d31d6`)
          .then(res => {
            return res.json();
          }).then(this.setResults);
      }
    },
    setResults (results) {
      this.weather = results;
    },
    dateBuilder () {
      let d = new Date();
      let months = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
      let days = ["Domenica", "Lunedi", "Martedi", "Mercoledi", "Giovedi", "Venerdi", "Sabato"];
      let day = days[d.getDay()];
      let date = d.getDate();
      let month = months[d.getMonth()];
      let year = d.getFullYear();
      return `${day} ${date} ${month} ${year}`;
    }
  }
}


</script>

<style scoped>

.location-box .location {
  color: #FFF;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  text-shadow: 1px 3px rgba(0, 0, 0, 0.25);
}
.location-box .date {
  color: #FFF;
  font-size: 20px;
  font-weight: 300;
  font-style: italic;
  text-align: center;
}
.weather-box {
  text-align: center;
}
.weather-box .temp {
  display: inline-block;
  padding: 10px 25px;
  color: #FFF;
  font-size: 90px;
  font-weight: 700;
  text-shadow: 3px 6px rgba(0, 0, 0, 0.25);
  background-color:rgba(255, 255, 255, 0.25);
  border-radius: 16px;
  margin: 15px 0px;
  box-shadow: 3px 6px rgba(0, 0, 0, 0.25);
}
.weather-box .weather {
  color: #FFF;
  font-size: 48px;
  font-weight: 700;
  font-style: italic;
  text-shadow: 3px 6px rgba(0, 0, 0, 0.25);
}

.main-box{
  height: 600px;
  border: solid;
  border-radius: 10px;
}

.fade-enter-from, .fade-leave-to{
  opacity: 0;
  transform: scale(0.2);
}
.fade-enter-active, .fade-leave-active{
    transition: all 1.5s ease;
  }
</style>