<template>
    <div class="navbar-home fixed-top">
        <nav class="navbar navbar-expand-lg navbar-dark">
            <div class="container-fluid">
                <a @mouseleave="scaleOut()" @mouseover="scale()" class="navbar-brand n-link mx-5 brand" href="/">RV</a>
            </div>
        </nav>
    </div>
</template>

<script>
import gsap from 'gsap'
    export default{
        name: "NavbarHome",
        methods:{
            scale(){
                gsap.to('.n-link', {
                scale:  1.3,
                duration: 0.6,
                borderColor: 'black'
                })
            },
            scaleOut(){
                gsap.to('.n-link', {
                scale:  1,
                duration: 1,
                borderColor: '#970a1b'
                })
            }
        }
    }
</script>

<style scoped>
.n-link{
  width: 45px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  color: #d9bfb3 !important;
  border: solid 2px #970a1b;
  border-radius: 8px;
  background-color: #1d1010;
}
/* .n-link:hover{
 animation: scale 3s linear infinite;
}

@keyframes scale {
  0% { transform: scale(1) }
  50% { transform: scale(1.3); background-color: #d9bfb3;}
  100% { transform: scale(1) }
} */
.brand{
    font-family: 'Bungee Inline', cursive;
    color: #970a1b !important;
}

</style>