import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MyProjects from '../views/MyProjects.vue'
import ContactView from '../views/ContactView.vue'
import PizzaHome from '../pizzaViews/PizzaHome.vue'
import FoodProduct from '../pizzaViews/FoodProduct.vue'
import CategoryPage from '../pizzaViews/CategoryPage.vue'
import PizzaCart from '../pizzaViews/PizzaCart.vue'
import SignUp from '../views/SignUp.vue'
import Weather from '../views/Weather.vue'
import IndexView from '../socialNetwork/Index.vue'
import ShowView from '../socialNetwork/ShowView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/my-projects',
    name: 'MyProjects',
    component: MyProjects
  },
  {
    path: '/contatti',
    name: 'ContactView',
    component: ContactView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/virtual-pizza',
    name: 'PizzaHome',
    component: PizzaHome
  },
  {
    path: '/registrati',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/:category_slug',
    name: 'CategoryPage',
    component: CategoryPage
  },
  {
    path: '/:category_slug/:product_slug/',
    name: 'FoodProduct',
    component: FoodProduct
  },
  {
    path: '/virtualPizza-cart',
    name: 'PizzaCart',
    component: PizzaCart
  },
  {
    path: '/meteo-app',
    name: 'Weather',
    component: Weather
  },
  {
    path: '/social-app',
    name: 'IndexView',
    component: IndexView
  },
  {
    path: '/social-app/:user_id',
    name: 'social-app',
    component: ShowView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
