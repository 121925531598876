<template>
  <NavbarHome class="mt-3"/>
  <div class="social-home">
    <div class="container-fluid">
     <div class="divider"></div>
     <div class="row">
      <div class="col-10 d-flex align-items-end">
        <h2 class="text-start title ms-3 mb-0"><strong>Meet our friends</strong></h2>
      </div>
      <div class="col-2">
        <h3 id="logo">S</h3>
      </div>
     </div>
      <hr class="mb-3" style="height: 2px; background-color: black; margin: 0;">
      <div class="container">
          <transition-group 
                        appear
                        tag="div"
                        class="row d-flex justify-content-around"
                        @before-enter="beforeEnter"
                        @enter="enter"
                            >
          <div v-for="(user, index) in usersList" :key="user.id" class="card my-2 col-lg-5" :data-index="index">
            <router-link class="route" :to="{ name: 'social-app', params:{ user_id: user.id } }">
              <div class="row">
                <div v-if="user.fields['Is requestable'] === true">
                  <img class="requestable" src="../assets/Yes.svg" alt="">
                </div>
                <div v-else>
                  <img class="requestable" src="../assets/No.svg" alt="">
                </div>
                
                <div class="col-lg-5 mb-3 p-0">
                    <img class="img-fluid profile-image ms-2 mb-2" :src="user.fields['Profile image'][0].url" alt="">
                </div>

                <div class="card-body d-flex-row pt-0 col-lg-6 offset-lg-1 px-1">
                  
                   <h6 class="text-start name ps-1"><strong>{{ user.fields.Name }}</strong></h6> 
                  
                  <p class="text-start text-muted ps-1 mb-0">{{ user.fields.Role }}</p>
                  <p class="card-body pt-0 ps-1 pb-0  text-start">
                    <small>
                      {{ user.fields.Quote }}
                    </small>
                  </p>
                  <div class="d-flex justify-content-start">
                    <i class="fa-brands fa-linkedin me-3 align-items-end"></i>
                    <i class="fa-brands fa-square-twitter align-items-end"></i>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </transition-group>
         
      </div>
      
      <div class="divider"></div>
    </div>
  </div>
</template>

<script>
import NavbarHome from '@/components/NavbarHome.vue';
import axios from 'axios';
import gsap from 'gsap';
// @ is an alias to /src

export default {
  name: 'IndexView',
  components:{
    NavbarHome
  },
  data(){
    return{
      usersList: [],
      api_token: 'keyEC9F0usYdVoWzg'
    }
  },
  created(){
    this.getUsersList()
  },
  methods:{
    async getUsersList(){
      await axios.get('https://api.airtable.com/v0/app7cLoZ4xMOrQvKR/People?api_key='+ this.api_token)
               .then(response =>{
                    this.usersList = response.data.records
                    console.log(response.data.records)
                    })
                    
                    .catch(error =>{
                                    console.log(error)
                                });
            document.title = "RV - Social-App"
    },
    beforeEnter(el){
          el.style.opacity = 0;
          el.style.transform = 'translateX(50px)';
    
        },
        enter(el, done){
          gsap.to(el, {
            opacity: 1,
            x:0,
            duration: 1,
            onComplete: done,
            delay: el.dataset.index * 0.2
          })
        }
  }
}
</script>

<style scoped>
/* background #e0dbd0 #f5f3ee */

.route{
  text-decoration: none !important;
  color: black;
}
.route:hover{
  color: rgb(67, 62, 62);
}
.social-home{
  background: linear-gradient(90deg, rgb(231, 204, 150) 0%, rgb(216, 176, 73) 94%);
  font-family: 'Kdam Thmor Pro', sans-serif;
}
.divider{
  height: 65px;
}
.name{
  font-weight: 600;
}
.profile-image{
  border-radius: 5px;
  /* -webkit-box-shadow: -6px 9px 14px -4px rgba(0,0,0,0.8);  */
  box-shadow: -6px 9px 14px -4px rgba(0,0,0,0.8);
  max-height: 200px;
}
.profile-box{
  position: absolute;
}
.requestable{
  position: relative;
  top: 30px;
  right: 0px;
  height: 20px;
  margin: 0;
  padding: 0;
}
.title{
  color: black;
}
.card{
  background: none;
}
#logo{
  margin-bottom: 0;
  font-size: 40px;
  font-family: 'Bungee Inline', cursive;
  color: #664508;
}
</style>
